<template>
  <div class="home">
    <custom-section class="section pa-0 pa-md-5">
      <NavbarOne
        class="d-md-none d-lg-none"
        :showNavbar="showNavbar"
        :showCart="showCart"
        :showCartDesktop="showCartDesktop"
        :showSearch="showSearch"
        :showLogo="showLogo"
      >
      </NavbarOne>
      <div class="mb-6 corp-m">
        <div class="payment-info">
          <v-data-table
            :options.sync="options"
            :headers="headerTable"
            :items="revenueList"
            hide-default-footer
            :loading="myloadingvariable"
            class="table-master-employee pa-3"
            :class="{ 'table-stick-master-employee': revenueList.length > 15 }"
            :mobile-breakpoint="0"
            :items-per-page="-1"
          >
            <template v-slot:top>
              <v-row
                class="d-flex flex align-center justify-space-between px-0 py-0"
              >
                <v-col cols="12" md="8" class="d-flex align-center pl-6">
                  <!-- <localized-link to="/company/employee/master/add">
                    <v-btn color="primary" dark class="button">
                      {{ $t("add") }}
                    </v-btn>
                  </localized-link> -->
                  <v-toolbar-title class="package-title text-capitalize pl-2"
                    >{{ $t("patient_appointment_list") }}
                  </v-toolbar-title>
                </v-col>
                <v-col cols="12" md="4" class="d-flex align-center pr-0">
                  <v-text-field
                    class="searchField fontSet pl-3 pr-3"
                    outlined
                    dense
                    hide-details
                    v-model="search"
                    :label="$t('search') + ' ...'"
                    append-icon="mdi-magnify"
                  ></v-text-field>

                  <div>
                    <v-select
                      class="footerHide"
                      v-model="perPage"
                      :items="itemShowing"
                    ></v-select>
                  </div>
                </v-col>
              </v-row>
            </template>
            <template v-slot:item="{ item }">
              <tr class="hd-sticky">
                <td class="pt-2 center-font">
                  <div class="x1"></div>
                </td>
                <td valign="top" class="pt-2 tb-wide center-font">
                  {{ item.start_time }} - {{ item.end_time }}
                </td>
                <td valign="top" class="pt-2 tb-wide center-font">
                  {{ item.day }}, {{ formatDateStandard(item.date) }}
                </td>
                <td valign="top" class="pt-2 center-font">
                  {{ item.medical_record_number }}
                </td>
                <td valign="top" class="pt-2">
                  {{ item.patient_name }}
                </td>
                <td valign="top" class="pt-2 center-font">
                  <a href="javascript:void(0);" @click="openDialogOrder(item)">
                    {{ item.order_number }}
                  </a>
                </td>
                <td valign="top" class="pt-2 tb-wide center-font">
                  <a
                    :href="item.zoom_link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <v-btn class="button-delete-employee fontSet" color="white">
                      {{ $t("start_appointment") }}
                    </v-btn>
                  </a>
                </td>
              </tr>
            </template>
            <template v-slot:footer>
              <v-row
                class="d-flex flex align-center justify-space-between footer-payment"
              >
                <v-col cols="12" md="8">
                  <p class="footer-showing">
                    showing {{ itemShowStart }} to {{ itemShowStop }} of
                    {{ itemsTotal }} entries
                  </p>
                </v-col>
                <v-col cols="12" md="4" class="d-flex justify-end">
                  <custom-pagination
                    @changePage="(val) => (page = val)"
                    :totalPage="itemLength"
                    :activePage="page"
                  />
                </v-col>
              </v-row>
            </template>
          </v-data-table>
          <DialogResult
            v-if="dialog_order"
            :item="dataDialogOrder"
            :tampilkan="dialog_order"
            @toggleDialog="(is_show) => toggleDialogOrder(is_show)"
          />
        </div>
      </div>
    </custom-section>
  </div>
</template>

<script>
import API from "@/service/api_service.js";
import Auth from "@/service/auth_service";
import moment from "moment";
import DialogResult from "@/components/v3/results/index.vue";
import CustomPagination from "@/components/v3/pagination.vue";
import CustomSection from "@/components/v2/section/index.vue";
import NavbarOne from "@/components/v2/topbar/navbar_one.vue";

export default {
  components: {
    DialogResult,
    CustomPagination,
    CustomSection,
    NavbarOne,
  },
  data: () => ({
    page: 1,
    options: null,
    itemsTotal: 0,
    itemLength: 1,
    itemShowStart: 0,
    itemShowStop: 0,
    itemShowing: [
      { value: 25, text: "25" },
      { value: 50, text: "50" },
      { value: 70, text: "70" },
      { value: 100, text: "100" },
    ],
    perPage: 25,
    myloadingvariable: true,
    activeTab: 2,
    checkItemMonth: false,
    checkItemYear: false,
    pageDataTable: 1,
    pageCountDataTable: 2,
    dataPerPage: 25,
    modalStartDate: false,
    modalEndDate: false,
    start_date: "",
    end_date: "",
    start_date_filter: "",
    end_date_filter: "",
    now: "",
    search: "",
    revenueList: [],
    totalPaid: 0,
    totalUnpaid: 0,
    totalRevenue: 0,
    listPerPage: [
      {
        value: -1,
        text: "All",
      },
      {
        value: 10,
        text: "10",
      },
      {
        value: 25,
        text: "25",
      },
      {
        value: 50,
        text: "50",
      },
    ],

    headerTable: [],

    dialog_order: false,
    dataDialogOrder: {
      order_id: null,
      list: [],
    },
    showNavbar: true,
    showCart: false,
    showCartDesktop: false,
    showSearch: false,
    showLogo: true,
  }),
  computed: {
    dateFormatedStart() {
      return this.formatDateNow(this.start_date);
    },
    dateFormatedEnd() {
      return this.formatDateNow(this.end_date);
    },
    dataProfile() {
      return this.$store.state.profileData;
    },
  },

  watch: {
    page: function () {
      this.getInitialRevenue();
    },
    perPage: function () {
      if (this.page != 1) {
        this.page = 1;
      } else {
        this.getInitialRevenue();
      }
    },
    search: function () {
      this.page = 1;
      this.getInitialRevenue();
    },
  },
  created() {
    this.headerTable = [
      {
        // text: "Time",
        // value: this.$t("time"),
        width: "30px",
        align: "center",
        sortable: false,
      },
      {
        text: this.$t("time"),
        value: "time",
        align: "center",
      },
      {
        value: "date",
        text: this.$t("date"),
        align: "center",
        width: "160px",
      },
      {
        text: this.$t("page.profile.order_no"),
        value: "order_number",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("page.profile.patient_name"),
        value: "patient",
        align: "center",
        width: "250px",
      },
      {
        text: this.$t("medical_record_number"),
        value: "medical_record_number",
        align: "center",
      },

      {
        text: this.$t("page.profile.actions"),
        align: "center",
        value: "actions",
      },
    ];
    this.getInitialRevenue();
  },
  methods: {
    async getInitialRevenue() {
      try {
        console.log("qwertyuiopasdfghj", this.dataProfile);
        console.log("xjndsfnsodnfsd", this.dataProfile.person.id);
        this.myloadingvariable = true;
        // pagination showing start of entries
        let startPage = (this.page - 1) * this.perPage;
        let showPage = this.perPage;
        this.itemShowStart = startPage + 1;
        this.itemShowStop = startPage + showPage;

        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}telemedicine/list-appointment?query=${this.search}&offset=${startPage}&per_page=${showPage}`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        if (res.statusCode == 200) {
          console.log("resuuu", res.results);
          this.revenueList = res.results;
        }

        this.now = this.formatDateNow(new Date());
        this.start_date = new Date(
          `${new Date().getFullYear()}-${
            new Date().getMonth() + 1
          }-${new Date().getDate()}`
        )
          .toISOString()
          .substr(0, 10);
        this.end_date = new Date().toISOString().substr(0, 10);

        this.itemsTotal = res.total;
        this.itemLength = Math.ceil(this.itemsTotal / showPage);
        if (this.itemShowStop < 0) {
          this.itemShowStop = res.total;
        }
        if (this.itemShowStop > res.total) {
          this.itemShowStop = res.total;
        }
        this.myloadingvariable = false;
      } catch (error) {
        console.log("Error 403!");
      }
    },
    toggleDialogOrder(toggle) {
      this.dialog_order = toggle;
    },
    async openDialogOrder(item) {
      this.dataDialogOrder.order_number = item.order_number;
      this.dataDialogOrder.type_test = item.type;
      this.getOrderDetailPopup();
    },
    async getOrderDetailPopup() {
      try {
        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}medical-record/inspection/list?order_number=${this.dataDialogOrder.order_number}`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        this.dataDialogOrder.results = res.results;
        this.dialog_order = true;
      } catch (error) {
        console.log("Error 403!");
      }
    },

    formatDateNow(param) {
      if (!param) return null;
      const date = new Date(param);
      // return `${("0" + date.getDate()).slice(-2)}-${(
      //     "0" +
      //     (date.getMonth() + 1)
      // ).slice(-2)}-${date.getFullYear()}`;
      return moment(date)
        .locale(this.$i18n.locale)
        .format("DD-MMM-YYYY")
        .toUpperCase();
    },
    formatDateStandard(value) {
      return moment(value)
        .locale(this.$i18n.locale)
        .format("DD-MMM-YYYY")
        .toUpperCase();
    },
  },
};
</script>

<style>
@media screen and (min-width: 1023px) {
  .sticky-c1 {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;

    background: #fff;
  }
  .sticky-c2 {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 155px;

    background: #fff;
  }
  .sticky-h1 {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    z-index: 5;
    background: #ddd;
  }
  .sticky-h2 {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 155px;
    z-index: 5;
    background: #ddd;
  }
}
.table-master-employee thead {
  background: #ddd !important;
  font-weight: bold;
  color: #000 !important;
}
.table-master-employee thead tr th {
  height: 38px !important;
  white-space: nowrap;
}
.table-master-employee tbody tr td {
  font-size: 12px !important;
  height: 35px !important;
  white-space: nowrap;
}
.table-master-employee tbody tr:hover {
  background-color: #eee !important;
}
</style>
<style lang="scss" scoped>
@import "@/assets/css/custom.scss";
@import "@/assets/scss/color.scss";
.package-title {
  font-size: 16px;
  font-weight: bold;
  color: $dark_blue_2;
  margin-bottom: 0;
}
.home {
  display: flex;
  flex-direction: column;
  padding: 1px 0 40px 0;
  &__section {
    padding: 0 20px;
  }
}
@media screen and (min-width: 1023px) {
  .hd-sticky:hover {
    .sticky-c1 {
      background: #eee !important;
    }
    .sticky-c2 {
      background: #eee !important;
    }
    .v-data-table
      > .v-data-table__wrapper
      tbody
      tr:first-child:hover
      td:first-child {
      background: #eee !important;
    }
  }
}
.searchField {
  max-width: 500px;
}
.button--active {
  background: $main_3;
  color: white;
}
.button--outline {
  color: $main_3;
  border: 1px solid $main_3;
  background: white;
}
.button--download {
  background: #79c4c6;
  color: white;
}
.button-box {
  box-sizing: border-box;
  border-radius: 10px;
  padding: 8px 16px;
  outline: none;
}
.card--result {
  background: $gray_3;
  padding: 18px;
  border-radius: 16px;
  width: 100%;
  // max-width: 300px;
  color: $gray_2;
  h4 {
    color: $gray_1;
    font-size: 20px;
  }
  p {
    margin: 0;
  }
  .card--result__item {
    padding: 6px 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $gray_2;
    &:nth-last-child(1) {
      border: none;
    }
  }
}

.status--inprogress {
  color: $yellow_1;
  font-weight: 600;
}
.status--complete {
  color: $green_1;
  font-weight: 600;
}
.status--cancel {
  color: $red_1;
  font-weight: 600;
}
.tabs {
  background: #e5e5e5 !important;
}
.content-table {
  padding: 15px;
  @media screen and (max-width: 960px) {
    padding: 5px 18px 18px 18px;
  }
}
@media screen and (max-width: 480px) {
  .content-table {
    padding: 0px !important;
  }
}
.fontSet {
  font-size: 14px !important;
}
.button {
  background-color: #00a4b3 !important;
  border-radius: 5px;
  color: white !important;
  font-size: 14px;
  text-align: center;
  text-transform: capitalize;
  height: 30px !important;
  min-width: 100px !important;
}
a {
  text-decoration: none;
}
.fontSet {
  font-size: 14px !important;
}
.box-page-new {
  margin: 20px;
  border-radius: 4px !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 12px;
  vertical-align: baseline;
  padding-top: 6px;
  padding-bottom: 6px;
  height: 25px !important;
}

.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  height: 35px !important;
}
.button-edit-employee {
  background-color: #00a4b3 !important;
  color: white;
  margin-right: 7px;
}
.button-delete-employee {
  background-color: #eefdfe !important;
  color: #00a4b3;
  margin-left: 7px;
}
.title-detail {
  color: #1a428a;
}
.text-title {
  font-size: 12px;
  text-transform: uppercase;
  color: #00a4b3;
  font-weight: bold;
}
.text-new-title {
  font-size: 12px;
  text-transform: uppercase;
  color: #000;
  font-weight: bold;
}
.text-new-value {
  font-size: 12px;
  text-transform: uppercase;
  color: #000;
}
.text-value {
  font-size: 12px;
  text-transform: uppercase;
  color: #000;
  text-align: right;
}
.title-information {
  font-size: 16px;
}
.list-data {
  padding: 12px 8px 0 8px !important;
}
</style>
<style>
.table-employee-company thead {
  background: #ddd !important;
  font-weight: bold;
  color: #000 !important;
}
.table-employee-company thead tr th {
  height: 38px !important;
  white-space: nowrap;
}
.table-employee-company tbody tr td {
  font-size: 12px !important;
  height: 35px !important;
  white-space: nowrap;
}
.table-employee-company tbody tr:hover {
  background-color: #eee !important;
}
.table-lists {
  padding: 4px 16px 4px 16px;
}
</style>
<style lang="scss">
.footerHide {
  background: none !important;
  align-items: center;
  width: 50px;
  font-size: 12px;
}
.footerHide > .v-data-footer__pagination {
  display: none !important;
}
.footerHide > .v-data-footer__icons-before {
  display: none !important;
}
.footerHide > .v-data-footer__icons-after {
  display: none !important;
}
.payment-info .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 12px;
  vertical-align: baseline;
  padding-top: 6px;
  padding-bottom: 6px;
}
.v-toolbar__title {
  font-size: 16px;
}
.footer-payment {
  margin: 0 auto !important;
}
.footer-showing {
  font-size: 12px !important;
  margin: 0 auto !important;
}

.footerHide > .v-text-field .v-input__prepend-inner,
.v-text-field .v-input__append-inner {
  padding-left: 0 !important;
}
</style>
<style>
/* Hide scrollbar for Chrome, Safari and Opera */
.table-stick-master-employee .v-data-table__wrapper::-webkit-scrollbar {
  height: 8px;
  width: 0px;
  border: 1px solid #fff;
}

.table-stick-master-employee .v-data-table__wrapper::-webkit-scrollbar-track {
  border-radius: 0;
  background: #eeeeee;
}

.table-stick-master-employee .v-data-table__wrapper::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: #b0b0b0;
}

/* Hide scrollbar for IE, Edge and Firefox */
.table-stick-master-employee .v-data-table__wrapper {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.super-dense > .v-data-table__wrapper > table > tbody > tr > td,
.super-dense > .v-data-table__wrapper > table > tbody > tr > th,
.super-dense > .v-data-table__wrapper > table > thead > tr > td,
.super-dense > .v-data-table__wrapper > table > thead > tr > th,
.super-dense > .v-data-table__wrapper > table > tfoot > tr > td {
  height: 35px !important;
}
.table-stick-master-employee .v-data-table__wrapper {
  overflow-x: auto;
  overflow-y: auto;
  height: 400px !important;
}
.table-stick-master-employee thead {
  position: sticky;
  inset-block-start: 0;
  top: 0;
  background: #ddd !important;
  font-weight: bold;
  color: #000 !important;
  z-index: 5;
}
.center-font {
  text-align: center !important;
}
.x1 {
  width: 20px;
  height: 20px;
  position: relative;
  border-radius: 50%;
  border: 5px solid red;
  background: #fff;
}
/* 
.x1:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 220px;
  height: 220px;
  border-radius: 50%;
  border: 6px solid #f22;
  background-size: 0px 100px;
} */
.aqua-outer-bg {
  background: aqua;
}
.pink-inner-bg:after {
  background-color: pink;
}
</style>
